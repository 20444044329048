import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const Accordion = makeShortcode("Accordion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "the-learning-curve",
      "style": {
        "position": "relative"
      }
    }}>{`The Learning Curve`}<a parentName="h2" {...{
        "href": "#the-learning-curve",
        "aria-label": "the learning curve permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Most good specs start with a picture:`}</p>
    <Aligner center mdxType="Aligner">
      <Image alt="Learning Curve Explainer" src="/images/FreeLearn_v3.png" mdxType="Image" />
    </Aligner>
    <h2 {...{
      "id": "simple-summary",
      "style": {
        "position": "relative"
      }
    }}>{`Simple Summary`}<a parentName="h2" {...{
        "href": "#simple-summary",
        "aria-label": "simple summary permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`A generalised template for online educational courses.`}</p>
    <h2 {...{
      "id": "abstract",
      "style": {
        "position": "relative"
      }
    }}>{`Abstract`}<a parentName="h2" {...{
        "href": "#abstract",
        "aria-label": "abstract permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The following specifies a set of contracts capable of supporting free online educational environments for learners, while ensuring that educators are rewarded for the content they create. This standard allows for the creation of any kind of course and only expects educators to ensure learners can claim their stake back after some period of time.`}</p>
    <h2 {...{
      "id": "motivation",
      "style": {
        "position": "relative"
      }
    }}>{`Motivation`}<a parentName="h2" {...{
        "href": "#motivation",
        "aria-label": "motivation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Student debt and underpaid teachers are two pivotal problems of our age. While an abundance of information exists online, especially in the open source world, we cannot simply make all resources free, as that ensures teachers become even more undervalued than they currently are. There must be some way of using programmable money to both erase student debt, while ensuring educators receive due recompense for the critical role they play in society.`}</p>
    <p>{`These contracts present exactly one such solution. An epistemic community of learners who choose to mint (increasingly valuable) LEARN tokens at the end of their course, rather than just claim their original stake back, is one possible emergent result.`}</p>
    <h2 {...{
      "id": "specification",
      "style": {
        "position": "relative"
      }
    }}>{`Specification`}<a parentName="h2" {...{
        "href": "#specification",
        "aria-label": "specification permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "deschool",
      "style": {
        "position": "relative"
      }
    }}>{`DeSchool`}<a parentName="h3" {...{
        "href": "#deschool",
        "aria-label": "deschool permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This contract contains the logic for creating courses, creating scholarships, registering learners, and tracking whose money has gone where and how much of the initial stake can be redeemed at any given time. It includes interfaces to the Learning Curve, which is the contract responsible for accepting DAI and minting LEARN (or vice versa); to a Yearn Vault; to the Yearn Registry (which stores a pointer to the latest vault contracts); and to an ERC20 implementation that includes the `}<inlineCode parentName="p">{`permit()`}</inlineCode>{` functionality necessary to ensure learners will only sign one transaction when registering for a course or redeeming their funds (rather than the approve-transact pattern we often see).`}</p>
    <h4 {...{
      "id": "methods",
      "style": {
        "position": "relative"
      }
    }}>{`Methods`}<a parentName="h4" {...{
        "href": "#methods",
        "aria-label": "methods permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`constructor`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address _stable`}</strong>{` - the kind of token stakes are denominated in;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address _learningCurve`}</strong>{` - the address of the contract containing the logic for minting & burning;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address _registry`}</strong>{` - the yearn registry of vaults, where stakes are kept during study.`}</p>
      </blockquote>
      <p>{`DeSchool is constructed to be aware of only these three items essential to its correct functioning.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`createCourse`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _stake`}</strong>{` - the amount of DAI to be locked for the duration of study;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _duration`}</strong>{` - the number of blocks for which the course runs, after which learners can redeem, or mint from, their stake;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`string calldata _url`}</strong>{` - a simple "metadata" field to link any course to its frontend, useful for validation `}{`&`}{` security, as well as future frontend displays;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address _creator`}</strong>{` - the address to receive any yield generated when learners on this course choose not to mint LEARN.`}</p>
      </blockquote>
      <p>{`Anyone can create a course. There are no privileged roles here. The only expectation is that you define clearly when learners may redeem whatever it costs to take your course, as well as the digital location where your course will take place.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`createScholarship`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{` - the course which the scholarships apply to;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _amount`}</strong>{` - the amount, in DAI, to be locked in a Yearn vault for perpetual scholarships;   `}</p>
      </blockquote>
      <p>{`Anyone can create scholarships for any course. All you need to do is lock up DAI in a yearn vault and specify for which course you'd like to open scholarships. The number of scholarships your DAI provides is calculated by dividing the amount provided by the specific course stake. The idea is that any learner can then register for the course as a scholar. Once they have completed the course - i.e. the duration of the course specified in number of blocks has passed - a new learner can then register with the same scholarship. Please read `}<a parentName="p" {...{
          "href": "/build/token-studies/free-learn/learnings/#perpetual-scholarships"
        }}>{`here`}</a>{` for more information.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`permitCreateScholarships`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:
`}<strong parentName="p">{`uint256 _courseId`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _amount`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 nonce`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 expiry`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint8 v`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 r`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 s`}</strong>{`  `}</p>
      </blockquote>
      <p>{`This method uses the `}<inlineCode parentName="p">{`permit()`}</inlineCode>{` functionality associated with DAI to approve the DeSchool contract to spend DAI on the caller's behalf and to execute the transaction in a single call. It does this by attaching the signature - which can be constructed using `}<inlineCode parentName="p">{`v`}</inlineCode>{`, `}<inlineCode parentName="p">{`r`}</inlineCode>{`, and `}<inlineCode parentName="p">{`s`}</inlineCode>{` - along with the nonce and expiry for that signature onto the call. Having this extra info enables us to then just call `}<inlineCode parentName="p">{`createScholarships()`}</inlineCode>{` and have it operate as expected.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`registerScholar`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{` - the course in which we need to refresh scholarship slots;    `}</p>
      </blockquote>
      <p>{`Called before `}<inlineCode parentName="p">{`permitandRegister`}</inlineCode>{` below if there are any scholarships available for this course. The idea here is that scholarships are handed out on a  first-come-first-serve basis. It is not about merit or outcome, both of which have their own problems. Because these scholarships are perpetual, we think that this approach is the most equitable.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`withdrawScholarship`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{` - the course from which to withdraw scholarships;`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _amount`}</strong>{` - the amount, in DAI, to be withdrawn from a Yearn vault;   `}</p>
      </blockquote>
      <p>{`A method which allows whomever has funded scholarships to withdraw their principal at any time. This reduces the scholarshipTotal for that course (i.e. if you withdraw the whole amount, no new scholars can register), though anyone already registered remains so for the duration of the course.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`batchDeposit`}</strong></p>
      <p>{`A function which can be called by anyone to take the current funds in DeSchool from what has been staked and move them into a Yearn vault. We need to document further the role played by keepers and strategies here, as well as potentially discuss the yRegistry.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`register`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _courseId`}</strong></p>
      </blockquote>
      <p>{`This method allows a learner to register for a course of their choice. It checks the stake associated with that course, accepts tokens amounting to that stake and registers the learner. The tokens are kept in the contract and assigned to the current batch. Once there are enough tokens in the batch to justify the gas costs of calling `}<inlineCode parentName="p">{`batchDeposit()`}</inlineCode>{`, anyone may do so and add the current batch to the yearn vault. Note that simply calling this route will require two transactions - one to approve this contract to spend your DAI, and another to actually spend it.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`permitAndRegister`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:
`}<strong parentName="p">{`uint256 _courseId`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 nonce`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 expiry`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint8 v`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 r`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 s`}</strong>{`  `}</p>
      </blockquote>
      <p>{`This method uses te `}<inlineCode parentName="p">{`permit()`}</inlineCode>{` functionality associated with DAI to approve the DeSchool contract to spend DAI on the caller's behalf and to execute the transaction in a single call. It does this by attaching the signature - which can be constructed using `}<inlineCode parentName="p">{`v`}</inlineCode>{`, `}<inlineCode parentName="p">{`r`}</inlineCode>{`, and `}<inlineCode parentName="p">{`s`}</inlineCode>{` - along with the nonce and expiry for that signature onto the call. Having this extra info enables us to then just call `}<inlineCode parentName="p">{`register()`}</inlineCode>{` and have it operate as expected.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`verify`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address _learner`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{`   `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`bool completed`}</strong></p>
      </blockquote>
      <p>{`All courses are deployed with a duration in number of blocks. This is a helper function that checks if a learner is on a course, and if the duration of that course has passedsince the registered. If if has, then the learner can either `}<inlineCode parentName="p">{`redeem()`}</inlineCode>{` or `}<inlineCode parentName="p">{`mint()`}</inlineCode>{` their stake. It is public, so anyone may check it at any time.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`redeem`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _courseId`}</strong></p>
      </blockquote>
      <p>{`If a learner is redeeming rather than minting, it means they are simply requesting their initial stake back (whether they have completed the course or not). In this case, the method checks what proportion of `}<inlineCode parentName="p">{`stake`}</inlineCode>{` (set when the course is deployed) must be returned and sends it back to the learner. Whatever yield they earned is allocated to the course creator, which they can choose to redeem at any point with another method described below.`}</p>
      <p>{`This contract contains no sense of "completing" a course based on some kind of examination or assessment, as we do not feel this is the direction in which modern education ought to trend. This method simply checks the period elapsed since `}<inlineCode parentName="p">{`blockRegistered`}</inlineCode>{`. If this contains more blocks than `}<inlineCode parentName="p">{`course.duration`}</inlineCode>{`, then the full `}<inlineCode parentName="p">{`stake`}</inlineCode>{` can be returned and the yield sent to the course creator.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`mint`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _courseId`}</strong></p>
      </blockquote>
      <p>{`Handles learner minting new LEARN and checks via `}<inlineCode parentName="p">{`verify()`}</inlineCode>{` what proportion of the stake to send to the Learning Curve. If `}<inlineCode parentName="p">{`mint`}</inlineCode>{` is chosen, the yield earned is still assigned to the course creator to balance incentives for educators as best as possible. The total stake is returned directly to the learner in the form of LEARN tokens, and we feel that the shape and design of the Learning Curve is enough to incentivize many learners to choose this option. However, if they don't, this is not really a concern, as we see this as a long-term project and will be satisfied if the supply of LEARN grows very slowly.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`withdrawYieldRewards`}</strong></p>
      <p>{`Transfers the amount of DAI that an address is eligible to withdraw. There may be yield from scholarships provided for their course, which is assigned as the scholarship is created and may be claimed at any time thereafter. There may also be yield from any learners who have registered in the case no scholarships are available. When the learner decides to redeem or mint their stake, this yield is assigned to the creator.  `}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`isDeployed`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _courseId`}</strong>{`  `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`bool deployed`}</strong></p>
      </blockquote>
      <p>{`Simply checks whether a learner's staked has been deployed to a Yearn vault or not so that we know where to fetch the DAI they are either redeeming or minting and whether we need to check for any yield to assign to the course creator.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`scholarshipAvailable`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{`   `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`bool`}</strong></p>
      </blockquote>
      <p>{`A helper function which returns whether there are any open scholarships for a given course.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getCurrentBatchTotal`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`uint256`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the total number of batches in our yearn vault.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getBlockRegistered`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:`}<br parentName="p"></br>{`
`}<strong parentName="p">{`address learner`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 _courseId`}</strong>{`    `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`uint256`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the block a given learner registered in.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getCurrentBatchId`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`uint256`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the current `}<inlineCode parentName="p">{`batchId`}</inlineCode>{`.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getNextCourseId`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`uint256`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the current `}<inlineCode parentName="p">{`courseId`}</inlineCode>{`.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getCourseUrl`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _courseId`}</strong>{`  `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`string memory`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the URL associated with the `}<inlineCode parentName="p">{`courseId`}</inlineCode>{` stored in the contract, mostly for security and independent verification/auditing purposes.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getYieldRewards`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`address redeemer`}</strong>{`  `}</p>
      </blockquote>
      <blockquote>
        <p parentName="blockquote">{`returns: `}<strong parentName="p">{`uint256`}</strong></p>
      </blockquote>
      <p>{`A helper get function which returns the current yieldRewards mapped to a given `}<inlineCode parentName="p">{`creator`}</inlineCode>{` address, should any educator wish to claim some of their earnings at any point.`}</p>
    </Accordion>
    <h4 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}>{`Events`}<a parentName="h4" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`CourseCreated`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 indexed courseId,`}<br parentName="p"></br>{`
`}{`uint256 stake,`}<br parentName="p"></br>{`
`}{`uint256 duration,`}<br parentName="p"></br>{`
`}{`uint256 url,`}<br parentName="p"></br>{`
`}{`uint256 creator  `}</p>
      </blockquote>
      <p>{`Likely the most important event in the long run, especially if we want to build a catalogue of courses, the URLs that they exist at, and the creator addresses which are receiving any yield. Tracking these events allows us to build a coherent and clear front-end for discovering the various courses using this standard.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`ScholarshipCreated`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 indexed courseId,`}<br parentName="p"></br>{`
`}{`uint256 scholarshipAmount,`}<br parentName="p"></br>{`
`}{`uint256 newScholars,`}<br parentName="p"></br>{`
`}{`uint256 scholarshipTotal
address scholarshipProvider,`}<br parentName="p"></br>{`
`}{`address scholarshipVault`}<br parentName="p"></br>{`
`}{`uint256 scholarshipYield  `}</p>
      </blockquote>
      <p>{`Another important event, intended to keep track of scholarships for different courses, who provided them, and the yield they're earning for the course creators.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`ScholarRegistered`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 indexed courseId,`}<br parentName="p"></br>{`
`}{`address scholar`}</p>
      </blockquote>
      <p>{`Useful for collecting offchain data for the total number of scholars ever registered on the coure. It is always possible to get the number of scholars currently registered by calling `}<inlineCode parentName="p">{`deschool.courses(_courseId)[5]`}</inlineCode>{`, but the `}<inlineCode parentName="p">{`scholars`}</inlineCode>{` number returned from that slot changes when scholarships are perpetuated, so is not an accurate historical record of total number of scholars ever registered.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`ScholarshipWithdrawn`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 scholarshipId,`}<br parentName="p"></br>{`
`}{`uint256 amountWithdrawn  `}</p>
      </blockquote>
      <p>{`Similar to ScholarRegistered, meant for keeping track accurately of scholarships for different courses, who has provided them and how much they have provided and withdrawn over time.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`LearnerRegistered`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 indexed courseId,`}<br parentName="p"></br>{`
`}{`address learner  `}</p>
      </blockquote>
      <p>{`Keep track of the addresses of learners and the courses they've signed up for.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`StakeRedeemed`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 courseId,`}<br parentName="p"></br>{`
`}{`address learner,`}<br parentName="p"></br>{`
`}{`uint256 amount  `}</p>
      </blockquote>
      <p>{`Useful for understanding which learners have redeemed how much for each course. This allows for two important things: understanding which courses learners are `}<inlineCode parentName="p">{`redeeming`}</inlineCode>{` on rather than `}<inlineCode parentName="p">{`minting`}</inlineCode>{`; and tracking how many learners (and how much of their original stake) are inactive in either the Vault or DeSchool.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`LearnMintedFromCourse`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 courseId,`}<br parentName="p"></br>{`
`}{`address learner,`}<br parentName="p"></br>{`
`}{`uint256 stableConverted,`}<br parentName="p"></br>{`
`}{`uint256 learnMinted  `}</p>
      </blockquote>
      <p>{`Tracked separately from `}<inlineCode parentName="p">{`LearnMinted`}</inlineCode>{` events in the Learning Curve so it's easy to see how much of the total supply of LEARN comes directly from learners going through the courses on offer.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`BatchDeposited`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`uint256 batchId,`}<br parentName="p"></br>{`
`}{`uint256 batchAmount,`}<br parentName="p"></br>{`
`}{`uint256 batchYieldAmount  `}</p>
      </blockquote>
      <p>{`Tracked to make it easy to see how much DAI is in each batch in the vault, and how many yDAI are associated with it for redemption or minting purposes.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`YieldRewardRedeemed`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`address redeemer,`}<br parentName="p"></br>{`
`}{`uint256 YieldRewarded   `}</p>
      </blockquote>
      <p>{`Helpful to keep track of how much yield has been claimed/sent back to the course creator at any given point.`}</p>
    </Accordion>
    <h3 {...{
      "id": "learning-curve",
      "style": {
        "position": "relative"
      }
    }}>{`Learning Curve`}<a parentName="h3" {...{
        "href": "#learning-curve",
        "aria-label": "learning curve permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This contract contains the logic for minting and burning LEARN based on the collateral that is sent to it. Importantly, it is open to anyone, which is what allows secondary markets for LEARN to form easily. The more collateral locked, the less LEARN is minted, using an exponentially decaying function. This ensures that the price of LEARN - defined as the number in existence divided by the underlying collateral in this contract - increases linearly. Please see `}<a parentName="p" {...{
        "href": "https://docs.google.com/spreadsheets/d/1hjWFGPC_B9D7b6iI00DTVVLrqRFv3G5zFNiCBS7y_V8/edit?usp=sharing"
      }}>{`this spreadsheet`}</a>{` for the source of the graphical depiction below.`}</p>
    <Aligner center mdxType="Aligner">
      <Image alt="Learn Curves" src="/images/learn-curves.png" mdxType="Image" />
    </Aligner>
    <h4 {...{
      "id": "methods-1",
      "style": {
        "position": "relative"
      }
    }}>{`Methods`}<a parentName="h4" {...{
        "href": "#methods-1",
        "aria-label": "methods 1 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`initialise`}</strong></p>
      <p>{`This is called only once, upon deployment, and is necessary for the maths to work. If we do not start at 1, then we end up with a DIV(0) error. The tokens are minted to the Learning Curve address itself, and so are unusable, as this seems most fair.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`permitAndMint`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params:
`}<strong parentName="p">{`uint256 _amount`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 nonce`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint256 expiry`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`uint8 v`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 r`}</strong><br parentName="p"></br>{`
`}<strong parentName="p">{`bytes32 s`}</strong>{`  `}</p>
      </blockquote>
      <p>{`This method uses te `}<inlineCode parentName="p">{`permit()`}</inlineCode>{` functionality associated with DAI to approve the LearningCurve contract to spend DAI on the caller's behalf and to execute the transaction in a single call. It does this by attaching the signature - which can be constructed using `}<inlineCode parentName="p">{`v`}</inlineCode>{`, `}<inlineCode parentName="p">{`r`}</inlineCode>{`, and `}<inlineCode parentName="p">{`s`}</inlineCode>{` - along with the nonce and expiry for that signature onto the call. Having this extra info enables us to then just call `}<inlineCode parentName="p">{`mint()`}</inlineCode>{` and have it operate as expected.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`mint`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _wad`}</strong>{` - the amount of DAI collateral to be swapped for LEARN`}</p>
      </blockquote>
      <p>{`This method allows anyone to mint LEARN tokens dependent on the amount of DAI they send. It is calculated according to the exponential decay above, which can be modelled with a natural logarithm. We use `}<a parentName="p" {...{
          "href": "https://github.com/hifi-finance/prb-math/tree/main/contracts"
        }}>{`this library`}</a>{` to help us calculate it, as exponents on chain are challenging.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`mintForAddress`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`address learner`}</strong>{`; `}<strong parentName="p">{`uint256 _wad`}</strong></p>
      </blockquote>
      <p>{`This is the same as a normal mint, except that an address is passed in which the minted LEARN is sent to. This is necessary to allow for mints directly from a Course, where we want to learner to receive LEARN, not DeSchool. It can also be used to mint LEARN to an address other than the one contributing collateral, which could - for instance - prove useful for donations.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`burn`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _burnAmount`}</strong>{` - how much underlying DAI the burner wishes to receive back.`}</p>
      </blockquote>
      <p>{`This function takes in some amount of LEARN to be burned, calculates how much underlying DAI collateral this corresponds to using the inverse operation of the natural logarithm decay function for minting - i.e. the natural exponent `}<inlineCode parentName="p">{`e`}</inlineCode>{` - burns the LEARN and returns the DAI to the sender.  `}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`e_calc`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 x`}</strong>{` - some number to be used in the calculation of exponents for `}<inlineCode parentName="p">{`burn`}</inlineCode>{` method calls.`}</p>
      </blockquote>
      <p>{`This function takes in some number `}<inlineCode parentName="p">{`x`}</inlineCode>{`, divides it by the global constant `}<inlineCode parentName="p">{`k`}</inlineCode>{` and returns the value of `}<inlineCode parentName="p">{`e`}</inlineCode>{` raised to the power of that number. This is used for the reverse operation of minting LEARN, here called `}<inlineCode parentName="p">{`burn`}</inlineCode>{`.  `}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`doLn`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 x`}</strong></p>
      </blockquote>
      <p>{`An internal, pure helper function that uses the PRBMathUD60x18 contract to return the natural logarithm of any number `}<inlineCode parentName="p">{`x`}</inlineCode>{` we pass to it.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getPredictedBurn`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 _burnAmount`}</strong>{` - DAI to receive`}</p>
      </blockquote>
      <p>{`A helper function wich calculates the amount of underlying DAI collateral to return for some `}<inlineCode parentName="p">{`_burnAmount`}</inlineCode>{` of LEARN tokens passed into it.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`getMintableForReserveAmount`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`params: `}<strong parentName="p">{`uint256 reserveAmount`}</strong>{` - DAI to lock`}</p>
      </blockquote>
      <p>{`A helper function to check if the learner has enough DAI to get the desired LEARN tokens and ensure a successful `}<inlineCode parentName="p">{`mint`}</inlineCode>{` transaction.`}</p>
    </Accordion>
    <h4 {...{
      "id": "events-1",
      "style": {
        "position": "relative"
      }
    }}>{`Events`}<a parentName="h4" {...{
        "href": "#events-1",
        "aria-label": "events 1 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`LearnMinted`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`address indexed learner,`}<br parentName="p"></br>{`
`}{`uint256 amountMinted,`}<br parentName="p"></br>{`
`}{`uint256 daiDeposited`}</p>
      </blockquote>
      <p>{`It is likely helpful to distinguish between `}<inlineCode parentName="p">{`LearnMintedFromCourse`}</inlineCode>{` and `}<inlineCode parentName="p">{`LearnMinted`}</inlineCode>{` by anyone interacting directly with the LearningCurve as secondary markets become more established, so that we can tell how much LEARN in existence comes from learning, and how much comes from speculation on the value of the resulting epistemic community.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`LearnBurned`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`address indexed learner,`}<br parentName="p"></br>{`
`}{`uint256 amountBurned,`}<br parentName="p"></br>{`
`}{`uint256 daiReturned`}</p>
      </blockquote>
      <p>{`The same reasoning applies as above: it is helpful to track LEARN created and destroyed by learners going through courses, and by other people interacting directly with these contracts. We have nothing against speculation, as such people take real risks and provide the necessary liquidity for more efficient markets to form.`}</p>
    </Accordion>
    <h4 {...{
      "id": "considerations",
      "style": {
        "position": "relative"
      }
    }}>{`Considerations`}<a parentName="h4" {...{
        "href": "#considerations",
        "aria-label": "considerations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <h5 {...{
      "id": "k-constant",
      "style": {
        "position": "relative"
      }
    }}>{`"k" constant`}<a parentName="h5" {...{
        "href": "#k-constant",
        "aria-label": "k constant permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h5>
    <p>{`Where does this magic constant come from? We were inspired by Uniswap's constant product formula and the simplicity it represents. While our setup is slightly different, we need some constant which defines the slope of the linearly increasing price function for LEARN. We do not think it possible, in principle, to model this with complete certainty. All we can do is ask increasingly precise questions about the kinds of outcome we might wish to see, especially in terms of how many LEARN will be minted by the course stake, if the learner chooses that option. `}</p>
    <p>{`We set `}<inlineCode parentName="p">{`k = 10000`}</inlineCode>{` because this means that 1M DAI must be locked as collateral before 100 DAI mints just 1 LEARN, an important psychological fact if nothing else.`}</p>
    <h2 {...{
      "id": "rationale",
      "style": {
        "position": "relative"
      }
    }}>{`Rationale`}<a parentName="h2" {...{
        "href": "#rationale",
        "aria-label": "rationale permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`There are no special accounts or privileged roles in these contracts. There are no fees collected by a specific account. That is because we are very serious about building a `}<strong parentName="p">{`general template for online education`}</strong>{` that cannot be co-opted and that both makes learning free, while also ensuring there are rewards available for educators.`}</p>
    <p>{`We are sure that there are even better ways to achieve this, and so have tried to outline in detail all our assumptions in order that this may be the beginning of a fruitful conversation about how programmable money might solve incentive problems surrounding the transfer of knowledge.`}</p>
    <p>{`The state of Ethereum will be around for a long, long time to come and so we are not here to get rich quickly, we are here to `}<a parentName="p" {...{
        "href": "/build/games/infinite"
      }}>{`play`}</a>{` with this vastly expanded `}<a parentName="p" {...{
        "href": "/learn/module-3/time"
      }}>{`temporal`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden"
      }}>{`boundary`}</a>{`. We have not written this for ourselves, but as a work of love and devotion to those yet to come.`}</p>
    <p>{`Education is the `}<a parentName="p" {...{
        "href": "/learn/module-7/"
      }}>{`original gift`}</a>{` and is especially interesting in gift-giving economies, because knowledge is given within a context that still demands effort and attention in order to be learned. Valuable knowledge may present itself for free, but it must be given attention and reflection in order to become personally meaningful. It is this paradox which makes educational gifts the seed around which a community crafting new value models can flourish. `}</p>
    <blockquote>
      <p parentName="blockquote">{`“A single flower blooms, and throughout the world it is spring”.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      